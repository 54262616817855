@import '/node_modules/flag-icons/css/flag-icons.min.css';
@import 'antd/dist/reset.css';

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT');
}

::before,
::after {
  --tw-content: '';
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: rgb(250, 250, 250);
}

.ant-pagination.ant-table-pagination {
  align-items: center !important;
  margin: 16px !important;
}

div.ant-select-selector {
  min-height: 48px !important;
  align-items: center !important;
}

span.ant-select-selection-search input {
  min-height: 48px !important;
  align-items: center !important;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

.ant-input-password span.ant-input-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

.text-align-right-input,
.text-align-right-input input {
  text-align: right;
}

/* Drawer */
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  max-width: 80%;
}

.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.1s,
    width 0s ease 0.1s;
}
.ant-drawer > * {
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1),
    box-shadow 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
    9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
    -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
    0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
    0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: rgba(26, 54, 64, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(26, 54, 64, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.1s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(26, 54, 64, 0.75);
  text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(26, 54, 64, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(26, 54, 64, 0.85);
  background: #fff;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(26, 54, 64, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.1s linear, height 0s ease 0.1s;
  pointer-events: none;
}
.ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}

.ant-upload .ant-upload-btn {
  padding: 12px !important;
}

.ant-upload-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.ant-upload-list-item-name {
  white-space: normal !important;
  height: 100%;
  display: none;
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 8px !important;
}

.ant-tabs-tab,
.ant-menu-submenu,
.ant-menu-item,
.ant-table-thead,
.ant-collapse-header {
  user-select: none;
}

@media (max-width: 767px) {
  [data-layout='layout'] {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  [data-layout='layout'] .left-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 16rem;
    --tw-translate-x: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  [data-layout='layout'][data-collapsed='true'] .left-sidebar {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .left-sidebar {
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
      0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 768px) {
  .main {
    margin-left: 250px;
  }

  [data-layout='layout'] {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  [data-layout='layout'][data-collapsed='true'] .left-sidebar {
    max-width: calc(0.25rem * 16);
  }

  [data-layout='layout'][data-collapsed='false'] .left-sidebar {
    max-width: calc(0.25rem * 64);
  }

  [data-layout='layout'] .left-sidebar {
    width: 100%;
    min-width: calc(0.25rem * 16);
    max-width: calc(0.25rem * 64);
    transition: max-width 0.25s ease-in-out;
  }

  .left-sidebar {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  [data-collapsed='true'] .left-sidebar ul > li {
    position: relative;
  }
}

.left-sidebar {
  position: fixed;
  transition: max-width 0.25s ease-in-out;
  /* background: linear-gradient(
    270deg,
    rgba(189, 28, 98, 0.04) 0%,
    rgba(239, 33, 108, 0.04) 100%
  ); */
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.disable-scrollbars {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ant-modal-footer {
  .ant-btn-primary {
    background: linear-gradient(270deg, #bd1c62 0%, #ef216c 100%);
  }
  .ant-btn-dangerous {
    background: #ff4d4f;
  }
}

.user-avatar-network {
  .ant-avatar-string {
    position: static !important;
  }
}
